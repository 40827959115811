import { Component, ViewEncapsulation } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,
  imports: [RouterModule],
  selector: 'kfd-root',
  templateUrl: './app.component.html',
  styleUrls: [
    '../../../../node_modules/primeicons/primeicons.css',
    '../../../../node_modules/primeng/resources/primeng.min.css',
    '../../../../node_modules/primeflex/primeflex.css',
    '../../../../libs/web-core/src/lib/styles/_index.scss',
    './app.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  title = 'cfg-web';
}
